import React from 'react'
import './style.scss'
import HeroImage from './hero.svg'
class Hero extends React.Component {
  constructor() {
    super()
    this.state = { loading: false, imgLoading: false }
  }
  componentDidMount() {
    const _this = this
    setTimeout(() => {
      _this.setState({
        loading: true,
      })
    }, 400)
    setTimeout(() => {
      _this.setState({
        imgLoading: true,
      })
    }, 2000)
  }

  render() {
    const { loading, imgLoading } = this.state
    const { isVisited } = this.props
    return (
      <>
        <div className={`shutter ${isVisited ? '' : 'active'}`}></div>
        <section
          id="hero"
          className={`${!isVisited ? (loading ? 'active' : '') : 'static'}`}
        >
          <div className="hero-card d-flex justify-content-center">
            <img
              src={HeroImage}
              className={`${
                !isVisited ? (imgLoading ? 'active' : '') : 'static'
              }`}
            />
          </div>
          <div className="press-text">
            <h1 className={`text-right`}>
              <div>
                <span>大学内に無料で学べる教育プラグインを</span>
              </div>
              <br />
              <div>
                <span>提供し、成長を加速させるエコシステムを作る</span>
              </div>
            </h1>
          </div>
        </section>
      </>
    )
  }
}

export default Hero
