import React from 'react'
import { Link } from 'gatsby'
import './style.scss'
class Concept extends React.Component {
  constructor() {
    super()
    this.state = { loading: false }
  }
  componentDidMount() {
    const _this = this
    setTimeout(() => {
      _this.setState({
        loading: true,
      })
    }, 400)
  }
  render() {
    const { loading } = this.state
    const { isVisited } = this.props
    return (
      <section
        id="concepts"
        className={`${!isVisited ? (loading ? 'active' : '') : 'static'}`}
      >
        <h2>Concepts</h2>
        <div className="row">
          <div className="col-12 col-sm-12 col-xl-4 mb-4">
            <article className="fors">
              <h3>For Students</h3>
              <h2>
                <div>
                  <span>Playground to</span>
                  <br />
                  <span>Real Society.</span>
                </div>
                <sub>社会と価値で繋がる学生を育む</sub>
              </h2>
            </article>
          </div>
          <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-4">
            <article className="forp">
              <h3>For Partner</h3>
              <h2>
                <div>
                  <span>Root for </span>
                  <br />
                  <span>Challenge.</span>
                </div>
                <sub>未来を創る立役者と共に歩む</sub>
              </h2>
            </article>
          </div>
          <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-4">
            <article className="forc">
              <h3>For Client</h3>
              <h2>
                <span>Be </span>
                <br />
                <span>"Technology Pool"</span>
                <sub>革新と拡張の触媒となる</sub>
              </h2>
            </article>
          </div>
        </div>
      </section>
    )
  }
}

export default Concept
