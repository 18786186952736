import React from 'react'
import './style.scss'
import Post from 'templates/post'
import get from 'lodash/get'

class Articles extends React.Component {
  constructor(props) {
    super(props)
    const categories = Array.from(
      new Set(
        this.props.articles
          .map(a => a.post.frontmatter.category.split(', '))
          .flat()
      )
    )
    this.state = {
      articles: props.articles,
      filterCategories: [],
      categories: categories,
    }
    this.handleOnSearch = this.handleOnSearch.bind(this)
    this.categoryHandleOnClick = this.categoryHandleOnClick.bind(this)
    this.clearHandleOnClick = this.clearHandleOnClick.bind(this)
  }
  handleOnSearch(e) {
    const baseArticles = this.props.articles
    const searchedArticles = baseArticles.filter((a, i) => {
      return (
        a.post.frontmatter.title
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
      )
    })
    const result = searchedArticles.length ? searchedArticles : baseArticles
    this.setState({ articles: result })
  }
  clearHandleOnClick() {
    this.setState({ filterCategories: [] })
  }
  categoryHandleOnClick(e) {
    const { filterCategories } = this.state
    const categoryName = e.target.innerText
    const index = filterCategories.findIndex(c => c === categoryName)
    if (index > -1) {
      filterCategories.splice(index, 1)
    } else {
      filterCategories.push(e.target.innerText)
    }
    this.setState({ filterCategories })
  }
  render() {
    const { defaultImg } = this.props
    const { articles, categories, filterCategories } = this.state
    let showArticles = []
    if (filterCategories.length) {
      showArticles = articles.filter((a, i) => {
        return a.post.frontmatter.category
          .split(', ')
          .some(a => filterCategories.includes(a))
      })
    } else {
      showArticles = articles
    }
    return (
      <section id="news">
        <div className="d-flex">
          <h2>Posts</h2>
          <div className="col"></div>
          <div
            className="input-group flex-nowrap"
            style={{ height: '38px', maxWidth: '400px' }}
          >
            <div className="input-group-prepend">
              <span className="input-group-text" id="addon-wrapping">
                <i className="fas fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search posts…"
              aria-label="Username"
              aria-describedby="addon-wrapping"
              onChange={this.handleOnSearch}
            />
          </div>
        </div>
        <div className="d-flex">
          <aside className="post-sidebar d-none d-md-block">
            <h3>Filter by:</h3>
            {filterCategories.length ? (
              <div onClick={this.clearHandleOnClick} className="clear">
                Clear filter
              </div>
            ) : (
              ''
            )}
            <ul>
              {categories.map(c => (
                <li key={c} onClick={this.categoryHandleOnClick}>
                  <span>{c}</span>
                  <i
                    className={`fas fa-check-square ${
                      filterCategories.includes(c) ? 'active' : ''
                    }`}
                  ></i>
                </li>
              ))}
            </ul>
          </aside>
          <div
            className="content col p-0"
            style={{
              minHeight: `${(parseInt(this.props.articles.length / 3) + 1) *
                330}px`,
            }}
          >
            <div className="row">
              {showArticles.map(({ post }, i) => (
                <Post
                  defaultImg={defaultImg}
                  data={post}
                  options={{
                    isIndex: true,
                  }}
                  key={`${post.frontmatter.path}_post_${i}`}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Articles
