import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import queryString from 'query-string'

import Articles from 'components/articles'
import Meta from 'components/meta'
import Layout from 'components/layout'
import Hero from 'components/hero'
import Concept from 'components/concept'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  const dImg = get(data, 'articleImg.childImageSharp.sizes')
  const isVisited =
    queryString.parse(location.search).ref === 'self' ? true : false
  return (
    <Layout location={location}>
      <Meta site={data.site.siteMetadata} />
      <Hero isVisited={isVisited} />
      <Concept isVisited={isVisited} />
      <Articles articles={posts} defaultImg={dImg.src} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    }
    articleImg: file(name: { eq: "article-img" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    remark: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
